import React from "react";
import success from "../../../Assets/img/success.svg";
import poweredBy from "../../../Assets/img/dreamcast_logo.svg";
import whatsAppIcon from "../../../Assets/img/whatsapp_icon.svg";
export default function Registered() {
  return (
    <section className="main-max-width">
      <div className="welcome-page text-center registered">
        <div>&nbsp;</div>
        <div className="w-100">
          <div className="regSuccessIcon">
            <img src={success} alt="" />
          </div>
          <h2 className="regSuccessText">Registered Successfully!</h2>
          <h3 className="regText">
          Now share & get pictures <br /> on your whatsapp <img src={whatsAppIcon} alt="" />
          </h3>
        </div>
        <div className="btn-share mt-5">
          <button className="btn large_click_btn share-button" onClick={() =>{window.open(`https://api.whatsapp.com/send?phone=919521275882`, "_blank", "noopener,noreferrer")}}>
            Continue
          </button>
        </div>
        <div className="powerd_by">
          <p>Powered by</p>
          <a href="https://godreamcast.com/" target="_blank" rel="noopener noreferrer">
            <img src={poweredBy} alt="" />
          </a>
        </div>
      </div>
    </section>
  );
}
