import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomLoader from "../../../components/CustomLoader";
import { saveAs } from "file-saver";
import { GalleryContext } from "../../../context";
import "react-image-lightbox/style.css";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import scanAgain from "../../../Assets/img/scan_again.svg";
import photoSelfy from "../../../Assets/img/my_photos.svg";
import nounGaller from "../../../Assets/img/noun-gallery.svg";
import userAvatar from "../../../Assets/img/default_user.svg";
import Loading from "../../../components/Loading";
import { API_BASE } from "../../../config/constant";
import downloadIcon from '../../../Assets/img/download_icon.svg'
import shareIcon from "../../../Assets/img/share-icon.svg";
import selectIcon from "../../../Assets/img/select-icon.svg";
import JSZip from "jszip";

export default function Gallery() {
  const {event, mobile} = useParams();
  const navigate = useNavigate();
  const { image, setImage, myImages, setMyImages, allImages, setAllImages, params, galleryButtonCheck, setGalleryButtonCheck } =
    useContext(GalleryContext);
  const [loading2, setLoading2] = useState();
  const [showGalleryButton, setShowGalleryButton] = useState(true);
  const [selectMode, setSelectMode] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isSticky, setIsSticky] = useState(false);

  const getGalleryImages = async () => {
    let body = {
      event_name: event,
      mobile: mobile,
    };
    setLoading2("showMyImages");
    try {
      const res = await axios.get(
        API_BASE + "api/my_gallery",
        { params: body }
      );

      if (res.data.data.length) {
        const images = res.data.data.map(({ image_url }) => image_url);
        setAllImages(images);
        setMyImages(images.filter((_, index) => index < 36));
    }else{
        setAllImages([]);
        setMyImages([]);
    }
    if(res.data.user_image){
        setImage(res.data.user_image)
    }
    } catch (error) {
      setAllImages([]);
      setMyImages([]);
      console.log(error);
    } finally {
      setLoading2(null);
    }
  };

  const checkButtonStatus = async() => {
    setLoading2("showMyImages");
    if(galleryButtonCheck === null){
      try{
        const body = {
          event_name: event
        }
  
        const res = await axios.post(API_BASE + "api/check_gallery_btn_status", body); 
        
        if(res.status === 200){
          setGalleryButtonCheck(true);
          setShowGalleryButton(res.data.gallery_button);
        }
  
      }catch(err){
        console.log(err);
      }
    }
  }

  useEffect(() => {
    checkButtonStatus().then(()=>{
      if(event && mobile){
          getGalleryImages();
      }
    });
    return () => {
      // setAllImages([]);
      setMyImages([]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadMoreImages = () => {
    setMyImages((prev) => [
      ...prev,
      ...allImages.slice(
        prev.length,
        Math.min(prev.length + 20, allImages.length)
      ),
    ]);
  };

  useEffect(() => {
    // Function to check if the header should be sticky
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleToggleSelectImages = (image) =>{
    const prevSelected = [...selectedImages];
    if (prevSelected.includes(image)) {
      setSelectedImages(prevSelected.filter((i) => i !== image));
    } else {
      setSelectedImages([...selectedImages, image]);
    }
  }

  const handleDownloadSelectedImages = () =>{
    const zip = new JSZip();
    const promises = Promise.all(
      selectedImages.map(async (url) => {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const name = url.split("/").pop();
          zip.file(name, blob, { binary: true });
        } catch (error) {
          console.log("zip generation error : ", error);
        }
      })
    )

    promises.then(() => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, `${event}.zip`);
      });
    }).finally(() => {
      setSelectMode(false);
      setSelectedImages([]);
    });

  }


  return (
    <>
      <section className="main-max-width">
        <div className="welcome-page text-center p-0 galleryPage">
          <div className={"header_app " + (isSticky ? "sticky" : "")}>
            <div className="header-top">
              <div className="header_pro_name col text-truncate">
                #{event}
              </div>
              <span
                onClick={
                  loading2
                    ? () => {}
                    : (event && mobile) ? 
                    () => navigate(`/${event}/${mobile}`)
                    : () =>
                        navigate(
                          `/${params?.eventName}/${params?.mobile}`
                        )
                }
                className="btn-scan"
              >
                <img src={scanAgain} className="me-2" alt="" /> Scan Again
              </span>
            </div>
            <div className={"userDetail header-content"}>
              <div className="">
                <img
                  className="object-fit-cover profile-image"
                  src={
                    image ||
                    userAvatar
                  }
                  alt=""
                />
              </div>
              {/* <h2 className="text-center header-text">
                  Rahul Mehra
              </h2> */}
            </div>
          </div>
          <div className="main-content-wrapper">
            <div className="image-selection-container">
              <p className="image-count-text"> Total {myImages.length} Photos</p>
              {selectMode ? (
                <button
                  className="btn btn-select-image"
                  onClick={() => {
                    setSelectMode(false);
                    setSelectedImages([]);
                  }}
                >
                  Cancel
                </button>
              ) : (
                <button 
                  onClick={() => setSelectMode(true)} 
                  className="btn btn-select-image"
                >
                  Select
                </button>
              )
              }
            </div>
            <div className="pL5 pR5">
            {(loading2 === "showMyImages" || loading2 === "showAll") ? 
            <Loading />
            :
              (<InfiniteScroll
                className={"customRow"}
                dataLength={myImages.length}
                next={loadMoreImages}
                hasMore={true}
                loader={
                  !myImages.length ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItemstems: "center",
                      }}
                    >
                      <CustomLoader
                        size={10}
                        color={"yellow"}
                        upTo={10}
                        speed={0.6}
                      />
                    </div>
                  ) : null
                }
              >
                {myImages?.map((image, index) => (
                  <div className={"col-4 event-gallery-image mB20"} key={index}>
                    <div
                      className="galleryBox"
                    >
                      <img
                        className="img"
                        src={image}
                        alt=""
                        loading="lazy"
                        onClick={() => {
                          if (selectMode) {
                            handleToggleSelectImages(image);
                          }else{
                            navigate(`/view-photo/${params?.eventName || event}/${params?.mobile || mobile}/gallery/${index}`)
                          }
                        }}
                      />
                      {selectedImages.includes(image) ? <div className="show-selected">
                        <img src={selectIcon} alt="" />
                      </div>
                      : null}
                    </div>
                  </div>
                ))}
              </InfiniteScroll>)
            }
            </div>
          </div>        
          {!selectMode ?
          (<div className="footerTab">
            <ul className="nav w-100" role="tablist">
              <li className="nav-item col">
                <Link
                  className={`nav-link active`}
                  data-bs-toggle="pill"
                  id="#home"
                  to={`/gallery/${params?.eventName || event}/${params?.mobile || mobile}`}
                >
                  <img src={photoSelfy} className="me-2" alt="" loading="lazy" />
                  My Photos
                </Link>
              </li>
              {showGalleryButton && <li className={`nav-item col`}>
                <Link
                  className={`nav-link`}
                  data-bs-toggle="pill"
                  id="#menu1"
                  to={`/event-gallery/${params?.eventName || event}/${params?.mobile || mobile}`}
                >
                  <img src={nounGaller} className="me-2" alt="" loading="lazy" />
                  Event Gallery{" "}
                </Link>
              </li>}
            </ul>
          </div>)
          : (
            <div className="footerTab bottom-drawer">
              <div className="left-content">
                {selectedImages.length + " images selected"}
              </div>
              <div className="right-content">
                <button
                  className="btn bottom-drawer-button d-none"
                >
                  <img src={shareIcon} alt="" />
                </button>
                <button
                  className="btn bottom-drawer-button"
                  onClick={() =>{handleDownloadSelectedImages()}}
                >
                  <img src={downloadIcon} alt="" />
                </button>
              </div>
            </div>
          )}
        </div>
      </section> 
    </>
  );
}
