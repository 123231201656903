import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import Webcam from "react-webcam";
import { uploadImage } from "../../../_api";
// import logo1 from "../../../Assets/img/logo-1.png";
// import logo2 from "../../../Assets/img/logo-2.png";
import imageCompression from "browser-image-compression";
import { GalleryContext } from "../../../context";
import cameraPic from "../../../Assets/img/camera_pic.svg";

export default function Capture() {
  const {event, mobile} = useParams();
  const { setMyImages, setImage, setAllImages, params, setParams } =
    useContext(GalleryContext);
  const navigate = useNavigate();
  const webCamRef = useRef(null);
  const [uploadedImg, setUploadedImg] = useState(null);

  useEffect(() => {
    if (event && mobile) setParams({ eventName: event , mobile });
  }, [event, mobile, setParams]);

  const [loading, setLoading] = useState(false);
  const [humanFaceError, setHumanFaceError] = useState({
    status: false,
    msg: "",
  });
  const [multipleFaceError, setMultipleFaceError] = useState({
    status: false,
    msg: "",
  });
  const [imgCompress, setImgCompress] = useState(null);
  const [scanText, setScanText] = useState("Scanning ..");

  const handleRetakeImage = () => {
    setUploadedImg(null);
    setLoading(false);
  };

  const handleFindImages = () => {
    setTimeout(() => {
      setScanText("Finding Images");
    }, 1500);
    setLoading(true);
    uploadFinalImage(uploadedImg);
  };

  const capture = () => {
    const imageSrc = webCamRef.current.getScreenshot();
    // setTimeout(() => {
    //   setScanText("Finding Images");
    // }, 1500);
    // setLoading(true);
    changeDataUrlToBlob(imageSrc);
  };

  const changeDataUrlToBlob = async (url) => {
    let blob = await fetch(url);
    blob = await blob.blob();
    setImgCompress(blob);
  };

  const compressImage = async () => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedImage = await imageCompression(imgCompress, options);
    const dataURL = await imageCompression.getDataUrlFromFile(compressedImage);
    // uploadFinalImage(dataURL);
    setUploadedImg(dataURL);
  };

  const uploadFinalImage = (dataURL) => {
    const body = new FormData();
    body.append("image", dataURL);
    body.append("event_name", event);
    body.append("mobile", mobile);
    uploadImage({
      params,
      body,
      setLoading,
      setHumanFaceError,
      setMultipleFaceError,
      navigate,
      setMyImages,
      setScanText,
      setImage: () => setImage(dataURL),
      setAllImages,
    });
  };

  useEffect(() => {
    if (imgCompress) compressImage();
  }, [imgCompress]); // eslint-disable-line

  useEffect(() => {
    window.scrollTo(0, 0);
    setImage(null);
  }, []); // eslint-disable-line

  const renderCapture = () => (
    <>
      <section className="main-max-width">
        <div className="welcome-page text-center">
          <div className="w-100 capturePart">
            <h6 className="welcome-note" style={{fontSize:"24px"}}>
              Center your face in the frame.
            </h6>
            <div className="descpertionSec takephotoSec">
              <div className="pictureArea dynmaicBg position-relative">
                <Webcam
                  id="my-video"
                  className="capturePic"
                  audio={false}
                  ref={webCamRef}
                  screenshotFormat="image/jpeg"
                  forceScreenshotSourceSize="true"
                  width={100 + "%"}
                  height={100 + "%"}
                  mirrored={true}
                  screenshotQuality={1}
                  videoConstraints={{
                    width: "1024",
                    height: "1024",
                    facingMode: "user",
                  }}
                  onUserMediaError={(err) => {
                    if(err.name === "NotAllowedError") {
                      alert("It seems your camera permissions are disable for your browser or for this site. Please enable the same in App settings under Phone Settings. Once done, please refresh this link.");                      
                    }else{
                      navigator.mediaDevices
                        .getUserMedia({ video: { facingMode: "user" } })
                        .then((stream) => {
                          window.stream = stream; // Save the stream for further use
                          
                        })
                        .catch((e) => {
                           if (e.name === "NotFoundError") {
                            alert("It seems your camera permissions are disable for your browser or for this site. Please enable the same in App settings under Phone Settings. Once done, please refresh this link.");
                          } else {
                            alert("Error accessing the camera: " + e.message);
                          }
                      });
                    }
                  }}
                />
                {/* </div> */}
                <div class="camera_pic"><img src={cameraPic} alt="" /></div>
              </div>
            </div>

            <div className="actionBlock btnAction m-0">
              <h6 className="welcome-note m-0 mb-3" style={{fontSize:"14px", fontWeight:"400"}}>Please include just one face per shot.</h6>
              <button
                className="btn large_click_btn"
                onClick={capture}
                disabled={!event}
              >
                {loading ? (
                  <i
                    className="fa fa-spinner fa-spin"
                    style={{ fontSize: "large" }}
                  />
                ) : (
                  "Click"
                )}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );

  const renderConfirmation = () => (
    <>
      <section className="main-max-width">
        <div className="welcome-page text-center">
          <div className="w-100 capturePart">
            <h6 className="welcome-note" style={{margin:"20px 10px"}}>Take a moment to confirm this picture!</h6>
            <div className="descpertionSec takephotoSec">
              <div className="pictureArea dynmaicBg">
                <img src={uploadedImg} alt="" />
              </div>
            </div>
            <div className="actionBlock btnAction confirmation-action">
              {humanFaceError?.status || loading ? (
                <div className="text-center w-100">
                  <span
                    className={
                      humanFaceError?.status ? "not-found" : "scanning"
                    }
                  >
                    {humanFaceError?.status ? humanFaceError?.msg : scanText}
                  </span>
                </div>
              ) : multipleFaceError?.status || loading ? (
                <div className="text-center  w-100">
                  <span
                    className={
                      multipleFaceError?.status ? "not-found" : "scanning"
                    }
                  >
                    {multipleFaceError?.status
                      ? multipleFaceError?.msg
                      : scanText}
                  </span>
                </div>
              ) : (
                <>
                  <button
                    className="btn retake-button"
                    onClick={handleRetakeImage}
                  >
                    Retake Photo
                  </button>
                  <button
                    className="btn large_click_btn confirm-button"
                    onClick={handleFindImages}
                  >
                    Find My Photos
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
  return (
    <section className="wrapper ellipse-bg">
      <div className="framephotoboothOuter">
        {!uploadedImg ? renderCapture() : renderConfirmation()}
      </div>
    </section>
  );
}
