import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Capture from "./Pages/PhotoBooth/Capture";
import Gallery from "./Pages/PhotoBooth/Gallery";
import "./Assets/css/bootstrap.min.css";
// import "./Assets/css/animation.css";
import "./Assets/css/style.css";
import "./Assets/css/custom.css";
import "./Assets/css/reset.css";
import "./Assets/css/effectanimation.css";
import "./Assets/css/responsive.css";
import NotFound from "./Pages/404";
import { GalleryContext } from "./context";
import Welcome from "./Pages/PhotoBooth/Welcome";
import Registered from "./Pages/PhotoBooth/RegisterSuccess/Registered";
import MyEventGallery from "./Pages/PhotoBooth/MyEventGallery";
import ViewPhoto from "./Pages/PhotoBooth/ViewPhoto";
import MainPage from "./Pages/PhotoBooth/Main";

export default function App() {
  const [myImages, setMyImages] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [image, setImage] = useState(null);
  const [params, setParams] = useState("");
  const [galleryButtonCheck, setGalleryButtonCheck] = useState(null);

  return (
    <>
      <BrowserRouter>
        <GalleryContext.Provider
          value={{
            myImages,
            setMyImages,
            image,
            setImage,
            allImages,
            setAllImages,
            params,
            setParams,
            galleryButtonCheck,
            setGalleryButtonCheck
          }}
        >
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/:event/:mobile" element={<Welcome />} />
            <Route path="/capture/:event/:mobile" element={<Capture />} />
            <Route path="/registered" element={<Registered />} />
            <Route
              path="/gallery/:event/:mobile"
              element={<Gallery />}
            />
            <Route
              path="/event-gallery/:event/:mobile"
              element={<MyEventGallery />}
            />
            <Route
              path="/view-photo/:event/:mobile/:from/:index"
              element={<ViewPhoto />}
            />
            <Route
              path="/view/:event/:mobile"
              element={<ViewPhoto />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </GalleryContext.Provider>
      </BrowserRouter>
    </>
  );
}
