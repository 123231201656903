import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import poweredBy from "../../../Assets/img/dreamcast_logo.svg";
import { GalleryContext } from "../../../context";
import arrowRight from "../../../Assets/img/arrow.svg"
import scanAnimation from "../../../Assets/img/face-scan.json";
import Lottie from "lottie-react";
import { API_BASE } from "../../../config/constant";
import axios from "axios";

export default function Welcome() {
  const {event, mobile} = useParams();
  const [isChecked, setIsChecked] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const { params, setParams } = useContext(GalleryContext);

  const checkForEvent = async () => {
    try {
      const res = await axios.post(API_BASE + `api/check_event_name`, {event_name : event});
      if (res.status === 200 && res.data.success === false) {
        navigate(`/`);
      } 
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (event && mobile) {
      setParams({ eventName: event , mobile })
      checkForEvent();
    };
  }, [event, mobile, setParams]); // eslint-disable-line

  const handleRedirect = (e) => {
    if (!isChecked) {
      e.preventDefault();
      setErrorMessage(
        "Please agree to the terms of service and privacy policy."
      );
    } else {
      setErrorMessage("");
      navigate(`/capture/${event}/${mobile}`);
    }
  };

  return (
    <section className="main-max-width">
      <div className="welcome-page text-center">
        <div className="w-100">
          <h3 className="projectName">#{event}</h3>
          <h6 className="welcome-note">
            Scan your face now to receive all your photos.
          </h6>
          <div className="takeSelfie">
            <div className="take-selfie-block" onClick={handleRedirect}>
              <div className="take-selfie-circle">
                {/* <img src={scanFace} /> */}
                <div className="take-selfie-inner">
                  <Lottie
                    animationData={scanAnimation}
                  />
                </div>
              </div>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id=""
                onChange={(e) => setIsChecked(e.target.checked)}
                name="agree"
                value={isChecked}
                checked={isChecked}
              />
              <label className="form-check-label">
                I Agree to the{" "}
                <span>
                  Terms of Service
                </span>
                . Learn about How we use and protect your data in our{" "}
                <span className="link">
                  Privacy Policy
                </span>
                .
              </label>
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <div className="actionBlock btnAction mt-3">
              <button
                className="btn large_click_btn"
                onClick={handleRedirect}
                disabled={!params?.eventName}
              >
                Scan My Face <img alt="" style={{marginLeft:"5px"}} src={arrowRight}/>
              </button>
            </div>
          </div>
        </div>
        <div className="powerd_by">
          <p>Powered by</p>
          <a href="https://godreamcast.com/" target="_blank" rel="noopener noreferrer">
            <img src={poweredBy} alt="" loading="lazy" />
          </a>
        </div>
      </div>
    </section>
  );
}
