import React, { useContext, useEffect, useState } from 'react';
import downloadIcon from '../../../Assets/img/download_icon.svg'
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { GalleryContext } from '../../../context';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../../components/Loading';
import { API_BASE } from '../../../config/constant';
import { saveAs } from "file-saver";
import { downloadImage } from '../../../_api';
import shareIcon from "../../../Assets/img/share-icon.svg";
import cancelIcon from "../../../Assets/img/cancel-icon.svg";

export default function ViewPhoto() {
    const { allImages, setAllImages, params } =
        useContext(GalleryContext);
    const { from, index, event, mobile } = useParams();
    const navigate = useNavigate();
    const [downloading, setDownloading] = useState(false);
    const [loading, setLoading] = useState(false);
    const showAllImages = async () => {
        setLoading(true);
        let body = {
            event_name: "#" + (params?.eventName || event),
            mobile: params?.mobile || mobile,
        };
        try {
            const res = await axios.post(
                API_BASE + "api/gallerylist",
                body
            );

            if (res?.data?.data?.length) {
                const images = res.data.data.map(({ image_url }) => image_url);
                setAllImages(images);
            } else {
                navigate(`/?event_name=${params?.eventName || event}&mobile=${params?.mobile || mobile}`);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const getGalleryImages = async () => {
        let body = {
            event_name: event,
            mobile: mobile,
        };
        setLoading(true);
        try {
            const res = await axios.get(
                API_BASE + "api/my_gallery",
                { params: body }
            );

            if (res.data.data.length) {
                const images = res.data.data.map(({ image_url }) => image_url);
                setAllImages(images);
            } else {
                setAllImages([]);
            }
            
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    };

    useEffect(() =>{
        if(allImages.length <= 0){
            if(from === 'gallery'){
                getGalleryImages();
            }else{
                showAllImages();
            }
        }
    },[from]) // eslint-disable-line 

    async function handleImageDownload(url) {
        
        if (!downloading) {
            setDownloading(true);
            const body = new FormData();
            body.append("image_url", url);
      
            downloadImage({ body:body, setLoading : setDownloading, saveAs: saveAs, event: event });
        }
    }

    return (
        <section className="main-max-width">
            <div className="welcome-page text-center p-0 galleryPage">
                {(loading) ? 
                <Loading />
                : null}
                {allImages.length > 0 && 
                <ImageGallery
                    items={allImages.map((image) => ({ original: image, loading: "lazy" }))}
                    showFullscreenButton={false}
                    showNav={false}
                    showPlayButton={false}
                    startIndex={parseInt(index)}
                    onSlide={(ind) => {
                        navigate(`/view-photo/${event}/${mobile}/${from}/${ind}`, { replace: true })
                    }}
                    lazyLoad={true}
                    infinite={false}
                />}
            </div>
            <div className="footerTab bottom-drawer">
              <div className="left-content">
                <span onClick={() => { navigate(`/${from}/${event}/${mobile}`, { replace: true }) }}>
                    <img src={cancelIcon} alt='' /> 
                    <p>Cancel</p> 
                </span>
              </div>
              <div className="right-content">
                <button
                  className="btn bottom-drawer-button d-none"
                >
                  <img src={shareIcon} alt="" />
                </button>
                <button
                  className="btn bottom-drawer-button"
                  onClick={() => {handleImageDownload(allImages[index])}}
                  disabled={downloading}
                >
                  <img src={downloadIcon} alt="" />
                </button>
              </div>
            </div>
        </section>
    )
}
