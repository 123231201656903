import React, { useEffect, useState } from "react";

export default function CustomLoader({ color, size, upTo, speed, className }) {
  const getCurrentStatus = (l) => {
    let arr = [];
    for (let i = 0; i < l; i++) {
      arr.push(
        <div
          style={{
            borderRadius: size / 2 || 3,
            width: size || 6,
            height: size || 6,
            background: color || "#fff",
            margin: 2,
          }}
          key={i}
        />
      );
    }
    return arr;
  };

  const [view, SetView] = useState(getCurrentStatus(1));

  useEffect(() => {
    let dots = getCurrentStatus(1);
    const loaderInterval = setInterval(() => {
      if (dots.length === (upTo || 4)) {
        dots = getCurrentStatus(1);
        SetView(dots);
      } else {
        dots = getCurrentStatus(dots.length + 1);
        SetView(dots);
      }
    }, 500 * (speed || 1));

    return () => {
      clearInterval(loaderInterval);
    };
  }, []); // eslint-disable-line

  return (
    <div className={className} style={{ display: "flex" }}>
      {view.map((dot) => dot)}
    </div>
  );
}
