import React from 'react'

export default function Loading() {
    return (
        <div className="overlay">
            <div className="overlay__inner">
                <div className="overlay__content">
                    <span className="spinner"></span>
                    <p>
                        Loading...
                    </p>
                </div>
            </div>
        </div>
    )
}
