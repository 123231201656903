import React, { useEffect } from 'react';
import $ from 'jquery';
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import '../../../Assets/css/home-reset.css';
import '../../../Assets/css/home.css';


export default function MainPage() {

    const settings = {
           dots: true,
           arrows: false,
           infinite: true,
           autoplay: true,
           speed: 1000,
           slidesToShow: 1
        }


    useEffect(() => {
        $(document).ready(function () {
            setTimeout(function () {
               $('.body_section').css('padding-top', $('header').outerHeight());
            }, 50);
            $(window).resize(function () {
               $('.body_section').css('padding-top', $('header').outerHeight());
            });

            $(window).scroll(function () {
               var scroll = $(window).scrollTop();
               if (scroll >= 50) {
                  $('header').addClass("fixed-top");
               } else {
                  $('header').removeClass("fixed-top");
               }
            });
            $(".playvideoBut").on('click', function () {
               $(".login-poup-overlay").addClass('model-open');
            });
            $(".close-btn").click(function () {
               $(".login-poup-overlay").removeClass('model-open');
            });

            // $(".simpleSlider").slick({
            //    dots: true,
            //    arrows: false,
            //    infinite: true,
            //    autoplay: true,
            //    speed: 1000,
            //    slidesToShow: 1,
            //    slidesToShow: 1,
            // });



         });

    }, []);


    return (
        <>
            <header className="navigation">
                <div className="container">
                    <div className="navbar-main">
                        <div className="navbar-Text">
                            <img border="0" src="https://cdn5.godcstatic.com/images/1810/picbot-logo.png"
                                alt="Picbot - Event photos sharing tool" width="200" height="42" />
                        </div>
                        <div className="navbar-list">
                            <div className="headerBut">
                                <a href="https://godreamcast.com/demo" rel="noopener noreferrer" target='_blank' className="btn btn-request playvideoBut"> Book A Demo</a>

                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <main className="innpage body_section">
                <section className="fest-hero">
                    <div className="inn-container">
                        <div className="fest-hero-area text-center">
                            <h1 className="text-primary"> AI-Powered Photo <br /> Distribution Tool for Events </h1>
                            <p className="h5 font-normal">Photo collection and distribution made super easy.</p>
                            <div className="inline-btn"><a rel="noopener noreferrer" href="https://godreamcast.com/demo" target='_blank' className="btn">Book A Demo</a> </div>
                            <div className="vid-sec"> <img alt="event Photos collection and distribution tool"
                                src="https://cdn5.godcstatic.com/images/1810/main-banner.png" /> </div>
                        </div>
                    </div>
                </section>
                <section className="big-problem">
                    <div className="inn-container bannerMainImg">
                        <div className="d-flex">
                            <div className="bannerImg order-2"> <img alt="Picbot Photos Sharing apps"
                                src="https://cdn5.godcstatic.com/images/1810/big-problem_img.png" /></div>
                            <div className="bannerRightContent bannerContentImg order-1">
                                <h2 className="text-white"> Big <br /> Problem</h2>
                                <p className="text-white">
                                    We never have our photographs taken by photographers or peers at events.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="trustedlogo">
                    <section className="brand-sec">
                        <div className="container-fluid">
                            <h2>Trusted &amp; Tried Globally</h2>
                            <div className="shaper"></div>
                        </div>
                    </section>
                </section>
                <section className="simple-solutionmain">
                    <div className="inn-container">
                        <h2 className="text-primary text-center">Simple Solution </h2>
                        <div className="serviceProductcommenMain">
                            <div className="serviceProductcommenLeft">
                                <div className="serviceBox">
                                    <div className="heading d-flex">
                                        <span className="leftIcon">
                                            <img alt="" src="https://cdn5.godcstatic.com/images/2110/arrow-left.png" />
                                        </span>
                                        <h4> Collect</h4>
                                        <span className="RightIcon">
                                            <img alt="" src="https://cdn5.godcstatic.com/images/2110/arrow-right.png" />
                                        </span>
                                    </div>
                                    <div className="serviceImg">
                                        <img alt="easily share event photos on WhatsApp"
                                            src="https://cdn5.godcstatic.com/images/1810/service-01.png" />
                                    </div>
                                    <div className="serviceText">
                                        Say “hello” to PicBot and “easily share event photos” on WhatsApp
                                    </div>
                                </div>

                                <div className="arrowPoint">

                                    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.16 16.36">
                                        <g id="Layer_1-2">
                                            <line className="cls-2 path" y1="8.18" x2="3" y2="8.18" />
                                            <line className="cls-1 path" x1="9.38" y1="8.18" x2="82.79" y2="8.18" />
                                            <line className="cls-2 path" x1="85.99" y1="8.18" x2="88.99" y2="8.18" />
                                            <polygon className="cls-3 arrow"
                                                points="82.36 16.36 81 14.89 88.22 8.18 81 1.46 82.36 0 91.16 8.18 82.36 16.36" />
                                        </g>
                                    </svg>
                                </div>

                            </div>
                            <div className="serviceProductcommenRight">
                                <div className="heading d-flex">
                                    <span className="leftIcon">
                                        <img alt="" src="https://cdn5.godcstatic.com/images/2110/arrow-large-left.png" />
                                    </span>
                                    <h4> Distribute</h4>
                                    <span className="RightIcon">
                                        <img alt="" src="https://cdn5.godcstatic.com/images/2110/arrow-large-right.png" />
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">

                                    <div className="serviceBox">

                                        <div className="serviceImg">
                                            <img alt="Find event photos using face recognition"
                                                src="https://cdn5.godcstatic.com/images/1810/service-02.png" />
                                        </div>
                                        <div className="serviceText">
                                            Have your <br /> face recognized.
                                        </div>
                                    </div>

                                    <div className="arrowPointRight">
                                        <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.16 16.36">
                                            <g id="Layer_1-2">
                                                <line className="cls-2 path" y1="8.18" x2="3" y2="8.18" />
                                                <line className="cls-1 path" x1="9.38" y1="8.18" x2="82.79" y2="8.18" />
                                                <line className="cls-2 path" x1="85.99" y1="8.18" x2="88.99" y2="8.18" />
                                                <polygon className="cls-3 arrow"
                                                    points="82.36 16.36 81 14.89 88.22 8.18 81 1.46 82.36 0 91.16 8.18 82.36 16.36" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="serviceBox">

                                        <div className="serviceImg">
                                            <img alt="Receive photos taken in an event"
                                                src="https://cdn5.godcstatic.com/images/1810/service-03.png" />
                                        </div>
                                        <div className="serviceText">
                                            You will receive photos taken by others.
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>




                    </div>

                    <div className="container mobileDeviceShow">
                        <div className="simpleSlider">
                            <Slider {...settings}>
                                <div className="itemslider">
                                    <div className="feat-name"> Collect</div>

                                    <img alt="" width="200" src="https://cdn5.godcstatic.com/images/1810/service-01.png" />
                                </div>
                                <div className="itemslider">
                                    <div className="feat-name"> Distribute</div>
                                    <img alt="" width="200" src="https://cdn5.godcstatic.com/images/1810/service-02.png" />
                                </div>
                                <div className="itemslider">
                                    <div className="feat-name"> Distribute</div>
                                    <img alt="" width="200" src="https://cdn5.godcstatic.com/images/1810/service-03.png" />
                                </div>
                            </Slider>
                        </div>
                    </div>

                </section>
                <section className="how-it-works">
                    <div className="inn-container">
                        <div className="text-center">
                            <h2 className="text-primary pb-4">How It Works?</h2>
                            <figure className="how-it-img">
                                <img src="https://cdn5.godcstatic.com/images/1810/how-it-works.png"
                                    alt="Working of Photos Sharing tool" />
                            </figure>
                        </div>
                    </div>
                    <div className="upcomingEventSection">
                        <div className="inn-container">
                            <div className="upcomingBox d-flex">
                                <div className="upcomingLeft">
                                    <h3 className="text-white"> Explore More Possibilities <br /> To Upscale Your Upcoming Event! </h3>
                                </div>
                                <div className="upcomingRight">
                                    <div className="inline-btn"> <a rel="noopener noreferrer" className="resource10 btn" href="https://godreamcast.com/demo" target='_blank'>Contact Sales</a> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sharing-review">
                    <div className="inn-container">
                        <div className="price-hd">
                            <h2 className="text-center text-primary">Picbot V/s Other Photo Sharing apps </h2>
                        </div>
                        <div className="d-flex justify-content-center" style={{position: "relative"}}>
                            <div className="vs order-2">
                                <img src="https://cdn5.godcstatic.com/images/1810/vs.png" width="64"
                                    alt="Picbot V/s Other Photos Sharing Tools" />
                            </div>
                            <div className="pr-bx order-1">
                                <div className="pr-hd h3">PicBot</div>
                                <div className="pr-cnt">
                                    <ul>
                                        <li>
                                            <span>
                                                First every <strong> crowdsourced app </strong> for photo collection & distribution.
                                            </span>

                                        </li>
                                        <li>
                                            <span>
                                                Even <strong>attendees</strong> can <strong>contribute with</strong> Event
                                                <strong>Photograper.</strong> </span>
                                        </li>
                                        <li>
                                            <span>
                                                Full user journey on <strong>Whatsapp,</strong> no external apps. </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="pr-bx order-3">
                                <div className="pr-hd h3">Other Photo Sharing Apps</div>
                                <div className="pr-cnt">
                                    <ul>
                                        <li> <span>
                                            No crowdsourcing of photo. </span>
                                        </li>
                                        <li> <span>
                                            Distribute the photos clicked & uploaded by Event photographer only. </span>
                                        </li>
                                        <li> <span>
                                            Takes the user to Web pages that break the user experience. </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="upper-footer gradientBg">
                    <div className="inn-container">
                        <div className="container ">

                            <div className="d-flex justify-content-between align-items-center looksMain">

                                <div className="looksLeft">

                                    <div className="heading">
                                        <img src="https://cdn5.godcstatic.com/images/1810/uper-heading.png" alt="Free Demo for Picbot" />

                                    </div>
                                    <div className="QRCodeMain">
                                        <div className="QRImg">
                                            <img src="https://cdn5.godcstatic.com/images/1810/qr-code.png" width="240"
                                                alt="QR code based event photos sharing tool" />
                                        </div>
                                        <div className="QRTitle">
                                            <h4> <a href="https://godreamcast.com/demo" target="_blank" rel="noopener noreferrer" className="text-white">Try A Demo </a></h4>
                                            <p className="text-white">
                                                Just scan this QR & get started
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div className="looksright">
                                    <img src="https://cdn5.godcstatic.com/images/1810/footer.png" width="402"
                                        alt="Distribute or find event photos" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer>
                    <div className="container">
                        <div className="row footerMenu-box">
                            <div className="footerlogo">
                                <a href="<?php echo $site_url; ?>">
                                    <img src="https://cdn5.godcstatic.com/images/1810/picbot-logo.png" width="200" alt="footer logo" />
                                </a>
                            </div>
                            <div className="footermenu order-1">
                                <ul>
                                    <li className="mobilehide font-md">
                                        ©2024 Dreamcast Inc.
                                    </li>
                                    <li className="font-md">
                                        <a href="https://godreamcast.com/terms-conditions" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                                    </li>
                                    <li className="font-md">
                                        <a href="https://godreamcast.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footerSocialIcon order-2">
                                <ul>
                                    <li><a href="https://www.facebook.com/godreamcast" target="_blank" rel="noopener noreferrer"><img
                                        src="https://cdn5.godcstatic.com/images/footer/facebook.svg" alt="Facebook" /> </a></li>
                                    <li><a href="https://twitter.com/godreamcast" target="_blank" rel="noopener noreferrer"> <img
                                        src="https://cdn5.godcstatic.com/images/footer/twiter.svg" alt="Twitter" /> </a></li>
                                    <li><a href="https://www.instagram.com/godreamcast/" target="_blank" rel="noopener noreferrer"> <img
                                        src="https://cdn5.godcstatic.com/images/footer/instagram.svg" alt="Instagram" /> </a></li>
                                    <li><a href="https://www.linkedin.com/company/godreamcast/" target="_blank" rel="noopener noreferrer"> <img
                                        src="https://cdn5.godcstatic.com/images/footer/linkdin.svg" alt="Linkedin" /></a></li>
                                    <li><a href="https://www.youtube.com/@godreamcast" target="_blank" rel="noopener noreferrer"> <img
                                        src="https://cdn5.godcstatic.com/images/footer/youtube.svg" alt="Youtube" /></a></li>
                                </ul>
                            </div>
                            <div className="copyright order-2 mobileDeviceShow">©2024 Dreamcast.co</div>
                        </div>
                    </div>
                </footer>
            </main>
        </>
    )
}
