import axios from "axios";
import { API_BASE } from "../config/constant";

// export const getAllImages = async ({
//   setImages,
//   setError,
//   setLoading,
//   page,
// }) => {
//   try {
//     const response = await axios.get(
//       `https://dreamcast.co.in/s3image/explorefile.php?page=${page}`
//     );
//     if (response?.status === 200) {
//       setImages((prevData) => [
//         ...prevData.filter((item) => item !== 1),
//         ...response.data,
//       ]);
//     }
//   } catch (err) {
//     console.log(err);
//     setError(true);
//   }
//   setLoading(false);
// };

export const uploadImage = async ({
  params,
  body,
  navigate,
  setLoading,
  setError,
  setHumanFaceError,
  setMultipleFaceError,
  setMyImages,
  setAllImages,
  setScanText,
  setImage,
}) => {
  try {
    const { data, status } = await axios.post(
      API_BASE + "api/storeimg",
      body
    );
    if (status === 200) {
      if (data.data && data.data.length) {
        const images = data.data.map(({ image_url }) => image_url);
        setAllImages(images);
        setMyImages(images.filter((_, index) => index < 20));
        setImage();
        if(Object.keys(params).length && params.eventName && params.mobile){
          navigate(`/gallery/${params.eventName}/${params.mobile}`);
        }else{
          navigate(`/gallery`); ///${body.event_name}/${body.mobile}
        }
      } else if (data?.message?.toLowerCase().includes("not")) {
        setLoading(false);

        // setError(true);
        navigate("/registered");
        // setTimeout(() => {
        //   setError(false);
        // }, 2500);
      } else if (data?.key === "nothumanface") {
        setLoading(false);

        setHumanFaceError({ status: true, msg: data?.message });
        setTimeout(() => {
          setHumanFaceError(true);
        }, 2500);
      } else if (data?.key === "multipleface") {
        setLoading(false);

        setMultipleFaceError({ status: true, msg: data?.message });
        setTimeout(() => {
          setMultipleFaceError(true);
        }, 2500);
      }
    }
  } catch (err) {
    setScanText("Scanning ..");
    setLoading(false);
  }
};

export const downloadImage = async ({ body, setLoading, saveAs, event }) => {
  try {
    const { data, status } = await axios.post(
      API_BASE + "api/download_image",
      body
    );
    if (status === 200) {
      saveAs(data.data, event);
    }
    setLoading(null);
  } catch (err) {
    setLoading(null);
  }
};
