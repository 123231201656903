import React, { useEffect } from "react";

export default function NotFound() {
  useEffect(function () {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        color: "#2c0c49",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>:(</h1>
      <h2>Page not found ..!</h2>
    </div>
  );
}
